<template>
  <b-modal
    id="modal_add_infraccion"
    :title="nombreModal"
    title-class="text-white"
    ok-only
    size="lg"
    :header-bg-variant="cabezeraModal"
    hide-footer
    varian
  >
    <!-- <template
      #modal-header="{ close }"
    >
      <h6 class="text-white pt-50">
        {{ nombreModal }}
      </h6>
      <b-button
        size="sm"
        variant="danger"
        @click="close()"
      >
        x
      </b-button>

    </template> -->
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <buscar-alumno
        v-if="vamosCrear"
        @alumnoSeleccionado="alumnoSeleccionado"
      />
      <div class="border rounded py-1">
        <h3 class="text-center text-primary">
          <strong>ESTUDIANTE:</strong> <span class=" ml-1 text-align-center text-primary">{{ estudianteSelect.nombres }}
            - <span class="text-danger">{{ estudianteSelect.numero_documento === '' ? 'sin documento' :estudianteSelect.numero_documento }}</span>
          </span>
        </h3>

      </div>
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- SERVICIOS -->
        <b-row>
          <b-col
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="selectedServicio"
              rules="required"
            >
              <b-form-group
                label="Seleccione el servicio"
                label-for="selectedServicio"
              >
                <b-form-select
                  id="selectedServicio"
                  v-model="infraccion.servicio_id"
                  :options="servicioOptions"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <!-- Descripción -->
            <validation-provider
              #default="validationContext"
              name="descripcion"
              rules="required"
            >
              <b-form-group
                label="Descripción (opcional)"
                label-for="descripcion"
              >
                <b-form-textarea
                  id="descripcion"
                  v-model="infraccion.motivo"
                  rows="3"
                  max-rows="6"
                  :state="getValidationState(validationContext)"
                  placeholder="Describa aquí.."
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Form Actions -->
        <div class="d-flex justify-content-between ">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="$bvModal.hide('modal_add_infraccion')"
          >
            <feather-icon
              icon="XIcon"
              size="14"
              class="mr-50"
            />
            <span class="align-middle">Cancelar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="cabezeraModal"
            type="submit"
          >
            <feather-icon
              :icon="vamosCrear ? 'SaveIcon' : 'RefreshCcwIcon'"
              size="14"
              class="mr-50"
            />
            <span class="align-middle">{{ vamosCrear ? 'Guardar' : 'Actualizar' }}</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import store from '@/store'
import {
  BButton, BModal, BForm, BFormGroup, BFormInvalidFeedback, BFormTextarea,
  BCol, BRow, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BuscarAlumno from './BuscarAlumno.vue'

export default {
  components: {
    BFormSelect,
    BButton,
    BCol,
    BRow,
    BModal,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    BuscarAlumno,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      required,
      vamosCrear: true,
      nombreModal: 'Formulario de interconsulta',
      nombreBotonModal: '',
      cabezeraModal: 'primary',
      infraccion: {},
      gravedadOptions: [],
      servicioOptions: [],
      naturalezaOptions: [],
      estudianteSelect: {},
    }
  },
  mounted() {
    // store.dispatch('triajes/getGravedad').then(res => {
    //   this.optionsGravedad = res.data.map(value => ({
    //     value: value.id, text: value.nombre,
    //   }))
    // })
  },
  created() {
    store.dispatch('triajes/fetchServiciosOcade')
      .then(response => {
        this.servicioOptions = response.data.data.map(value => ({
          value: value.id, text: value.nombre,
        }))
      })
    this.$parent.$on('editarInfraccionMod', this.editarInfraccionMod)
    this.$parent.$on('nuevaInfraccion', this.nuevaInfraccion)
  },
  methods: {
    alumnoSeleccionado(item) {
      // console.log('ALUMNO SELCET: ', item)
      this.$set(this.estudianteSelect, 'nombres', `${item.persona.apellido_paterno} ${item.persona.apellido_materno} ${item.persona.nombres}`)
      this.$set(this.estudianteSelect, 'usuario_id', item.id)
      this.$set(this.estudianteSelect, 'numero_documento', item.persona.numero_documento)
    },

    onSubmit() {
      if (this.estudianteSelect.usuario_id === undefined && this.vamosCrear) {
        this.toastAlert('Debe de seleccionar al paciente')
        // this.$toast({
        //   component: ToastificationContent,
        //   position: 'top-right',
        //   props: {
        //     title: '¡Advertencia!',
        //     text: 'Debe de seleccionar a un estudiante',
        //     icon: 'AlertCircleIcon',
        //     variant: 'warning',
        //   },
        // })
        return
      }
      if (this.vamosCrear) {
        this.createInfraccion()
      } else {
        this.updateInfraccion()
      }
    },
    createInfraccion() {
      this.infraccion.usuario_id = this.estudianteSelect.usuario_id
      store.dispatch('triajes/addDerivado', this.infraccion)
        .then(() => {
          // console.log('RESPUESTA DE ADD CONVOCATORIA: ', res)
          this.toastSuccess()
          this.$emit('actualizarTablaInfracciones')
          this.$bvModal.hide('modal_add_infraccion')
        }).catch(() => {
          this.toastError()
        })
    },
    updateInfraccion() {
      const data = {
        id: this.estudianteSelect.id,
        data: this.infraccion,
      }
      store.dispatch('infracciones-store-module/updateInfraccion', data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Infraccion actualizada!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$emit('actualizarTablaInfracciones')
          this.$bvModal.hide('modal_add_infraccion')
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Error!',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    limpiarFormulario() {
      this.infraccion = {
      }
      this.estudianteSelect = {}
    },
    nuevaInfraccion() {
      this.nombreModal = 'Nueva Infracción'
      this.nombreBotonModal = 'Grabar'
      this.cabezeraModal = 'primary'
      this.vamosCrear = true

      this.limpiarFormulario()
      this.$bvModal.show('modal_add_infraccion')
    },

    editarInfraccionMod(dataEditInfraccion) {
      this.nombreModal = 'Actualizar Infracción'
      this.nombreBotonModal = 'Actualizar'
      this.cabezeraModal = 'warning'
      // console.log('dataEditInfraccion:', dataEditInfraccion)
      this.vamosCrear = false
      this.limpiarFormulario()
      this.infraccion.usuario_id = dataEditInfraccion.usuario_id
      this.infraccion.servicio_id = dataEditInfraccion.servicio_id
      this.infraccion.naturaleza_id = dataEditInfraccion.naturaleza_id
      this.infraccion.gravedad_id = dataEditInfraccion.gravedad_id
      this.infraccion.descripcion = dataEditInfraccion.descripcion
      this.$set(this.estudianteSelect, 'nombres', `${dataEditInfraccion.persona.apellido_paterno} ${dataEditInfraccion.persona.apellido_materno} ${dataEditInfraccion.persona.nombres}`)
      this.$set(this.estudianteSelect, 'id', dataEditInfraccion.id)
      this.$set(this.estudianteSelect, 'numero_documento', dataEditInfraccion.persona.numero_documento)
      this.$bvModal.show('modal_add_infraccion')
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

}
</script>

<style lang="scss" scoped>
</style>
