<template>
  <!-- modal login-->
  <b-modal
    id="modal-aprobar-servicios"
    ref="refModalAprobarServicios"
    v-model="dialog"
    :hide-footer="true"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    header-bg-variant="primary"
  >
    <template #modal-header>
      <div class="text-white">
        <strong class="float-left"> {{ formTitle }} </strong>
      </div>
    </template>

    <b-card
      no-body
      border-variant="light"
    >
      <b-card-body class="pb-50 pt-50">
        <h3 class="text-center text-primary">
          <strong>PACIENTE:</strong> {{ triajeData.persona.nombre_completo }} - <span class="text-danger">{{ triajeData.persona.numero_documento === '' ? 'sin documento' : triajeData.persona.numero_documento }}</span>
          <b-button
            class="ml-1"
            size="sm"
            variant="success"
            @click="showAdd = !showAdd"
          >
            <feather-icon
              icon="ArrowDownCircleIcon"
              size="16"
            />
          </b-button>
          <b-button
            class="ml-1"
            size="sm"
            variant="outline-danger"
            @click="close"
          >
            <feather-icon
              icon="XIcon"
              size="16"
            />
          </b-button>
        </h3>
      </b-card-body>
    </b-card>

    <b-card
      v-if="showAdd"
      no-body
    >
      <div class="m-2">
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col cols="12">
              <!-- Input servicio -->
              <b-form-group
                label="Adicionar Servicios"
              >
                <v-select
                  v-model="triajeData.servicio"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nombre"
                  :options="optionsTipos"
                  :reduce="val => val.id"
                  placeholder="Seleccionar"
                  @input="agregarServicios"
                />
              </b-form-group>
            </b-col>

            <!-- <b-col cols="12">
              <b-form-group label="Celular para contacto">
                <b-form-input
                  v-model="triajeData.celular"
                  type="text"
                  required
                />
              </b-form-group>
            </b-col> -->
          </b-row>
        </b-form>
      </div>
    </b-card>

    <b-row class="mt-1">
      <!-- <b-col
        sm="12"
        md="12"
        lg="4"
      >
        <b-card
          no-body
          border-variant="info"
        >
          <b-card-header class="pb-50 pt-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="50"
                  variant="light-info"
                >
                  <feather-icon
                    size="25"
                    icon="CalendarIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h6 class="font-weight-bolder mb-0">
                  Servicios desde una cita
                </h6>
              </b-media-body>
            </b-media>
          </b-card-header>
          <b-table-simple
            class="position-relative"
            striped
            bordered
            borderless
            small
            responsive
          >
            <b-thead>
              <b-tr>
                <b-th variant="secondary">
                  N°
                </b-th>
                <b-th variant="primary">
                  Servicio
                </b-th>
                <b-th>Acción</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(item, index) of serviciosCitas"
                :key="index"
              >
                <b-td>{{ index+1 }}</b-td>
                <b-td>
                  {{ item.nombre }}
                </b-td>
                <b-td>
                  <b-button
                    :variant="item.pivot.estado === 'aprobado' ? 'success' : 'danger'"
                    size="sm"
                    :value="item.pivot.estado"
                    @click="crearFUACitas(item)"
                  >
                    {{ item.pivot.estado === 'aprobado' ? 'Aprobadó' : 'Por Aprobar' }}
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col> -->

      <!-- Triaje servicios  -->
      <b-col
        sm="12"
        md="6"
        lg="6"
      >
        <b-card
          no-body
          border-variant="danger"
        >
          <b-card-header class="pb-50 pt-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="50"
                  variant="light-danger"
                >
                  <feather-icon
                    size="25"
                    icon="ThermometerIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h6 class="font-weight-bolder mb-0">
                  Servicios en triaje
                </h6>
              </b-media-body>
            </b-media>
          </b-card-header>

          <b-table-simple
            class="position-relative"
            striped
            bordered
            borderless
            small
            responsive
          >
            <b-thead>
              <b-tr>
                <b-th>N°</b-th>
                <b-th>Servicio</b-th>
                <b-th>Acción</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(item, index) of serviciosTriaje"
                :key="index"
              >
                <b-td>{{ index+1 }}</b-td>
                <b-td>{{ item.nombre }}</b-td>
                <b-td>
                  <!-- {{ item.pivot }} -->
                  <b-button
                    :variant="item.pivot.estado === 'aprobado' ? 'success' : 'danger'"
                    size="sm"
                    :value="item.pivot.estado"
                    class="mr-1"
                    @click="crearFUATriaje(item.pivot)"
                  >
                    {{ item.pivot.estado === 'aprobado' ? 'Aprobado' : 'Aprobar' }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="btn-icon"
                    size="sm"
                    @click="eliminarServicio(item.pivot)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                    />

                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>

      <b-col
        sm="12"
        md="6"
        lg="6"
      >
        <b-card
          no-body
          border-variant="warning"
        >
          <b-card-header class="pb-50 pt-50">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="50"
                  variant="light-warning"
                >
                  <feather-icon
                    size="25"
                    icon="ChevronsRightIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h6 class="font-weight-bolder mb-0">
                  Servicios para interconsulta
                </h6>
              </b-media-body>
            </b-media>

          </b-card-header>
          <!-- <b-card-body /> -->
          <b-table-simple
            class="position-relative"
            striped
            bordered
            borderless
            small
            responsive
          >
            <b-thead>
              <b-tr>
                <b-th>N°</b-th>
                <b-th>Servicio</b-th>
                <b-th>Acción</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(item, index) of serviciosInter"
                :key="index"
              >
                <b-td>{{ index+1 }}</b-td>
                <b-td>{{ item.nombre }}</b-td>
                <b-td>
                  <b-button
                    :variant="item.pivot.estado === 'aprobado' ? 'success' : 'danger'"
                    size="sm"
                    :value="item.pivot.estado"
                    @click="crearFUAInterconsulta(item)"
                  >
                    {{ item.pivot.estado === 'aprobado' ? 'Aprobadó' : 'Por Aprobar' }}
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>

</template>

<script>
import {
  BMedia, BAvatar, BMediaBody, BMediaAside, BTableSimple, BThead, BTbody, BTr, BTd, BTh, BRow, BCol, BForm, BModal, BFormGroup,
  // BFormInput,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  // BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
  ref, watch, toRefs, nextTick, computed,
} from '@vue/composition-api'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

export default {
  components: {
    // BTable,
    BCard,
    BRow,
    BCol,
    BModal,
    BForm,
    BFormGroup,
    // BFormInput,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BCardBody,
    BCardHeader,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    editedItem: {
      type: Object,
      default() {
        return { }
      },
    },
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'id', label: 'N°' },
        { key: 'servicio' },
        { key: 'accion' },
      ],
      showAdd: false,
      // Spanish,
      selected: null,
      selectedEstado: null,
      optionsTipos: [],
      optionsEstado: [{ id: 1, nombre: 'aprobado' }, { id: 2, nombre: 'no aprobado' }, { id: 3, nombre: 'cancelado' }],
    }
  },
  created() {
    store.dispatch('triajes/fetchServiciosSalud').then(response => { this.optionsTipos = response.data.data })
  },
  methods: {
    eliminarServicio(item) {
      this.$swal({
        title: '¿Estas seguro?',
        text: '¡No podrás revertir esto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, bórralo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('triajes/deleteServicio', item)
            .then(response => {
              this.toastSuccess(`Se eliminó el servicio N° ${item.id}`, 'top-right', '¡Eliminado correctamente!')
              this.$emit('refresh-data-citas-table')
              this.serviciosTriaje = this.serviciosTriaje.filter(i => i.pivot.servicio_id !== response.data.servicio_id)
            })
            .catch(error => {
              this.toastError(`${error.response.data.error}`)
              this.$swal({
                title: 'Error',
                text: `${error.response.data.error}`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Tu servicio solicitado está seguro :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
  setup(props, { emit }) {
    // const { persona } = toRefs(props)
    const { editedItem } = toRefs(props)

    const editedIndex = ref(-1)
    const dialog = ref(false)
    const toast = useToast()

    const serviciosCitas = ref([])
    const serviciosTriaje = ref([])
    const serviciosInter = ref([])

    const blankTriajeData = {
      id: null,
      paciente_id: null,
      servidor_publico: null,
      motivo: null,
      celular: null,
      estado: 'no aprobado',
      fecha_cita: null,
      servicio: null,
      servicios: [],
      persona: {
        nombre_completo: null,
        numero_documento: null,
      },
    }

    const blankAtencion = {
      paciente_id: null,
      servidor_publico: null,
      triaje_id: null,
      servicio_id: null,
    }

    const atencionData = ref(JSON.parse(JSON.stringify(blankAtencion)))

    const triajeData = ref(JSON.parse(JSON.stringify(blankTriajeData)))

    const resetuserData = () => {
      triajeData.value = JSON.parse(JSON.stringify(blankTriajeData))
    }

    const close = () => {
      dialog.value = false
      nextTick(() => {
        resetuserData()
        editedIndex.value = -1
      })
    }

    const formTitle = computed(() => (editedIndex.value === -1 ? 'Creación de FUAs' : `Agregar servicios - N° ${editedIndex.value}`))

    watch(dialog, () => {
      // eslint-disable-next-line no-unused-expressions
      dialog.value || close()
    })

    watch(editedItem, () => {
      editedIndex.value = editedItem.value.id
      // Datos de triaje
      triajeData.value = editedItem.value
      serviciosCitas.value = editedItem.value.cita_id ? editedItem.value.cita.servicios : []
      serviciosTriaje.value = editedItem.value.servicios.length ? editedItem.value.servicios : []

      // Obtenemos todas las atenciones que al menos tengan un servicio asignado que seria una interconsulta
      const atencionesServicios = editedItem.value.atencion.filter(item => item.servicios.length > 0)

      // Debemos almacenar en un Array todos los servicios
      const datos = []
      atencionesServicios.forEach(item => {
        datos.push(item.servicios)
      })
      serviciosInter.value = datos.flat()
      // Para Formulario de Atención
      atencionData.value.paciente_id = editedItem.value.paciente_id
      atencionData.value.servidor_publico = editedItem.value.servidor_publico_id
      atencionData.value.triaje_id = editedItem.value.id

      dialog.value = true
    })

    const aprobarCita = () => {
      editedItem.value.estado = 'aprobado'
      editedItem.value.cita_id = editedItem.value.id
      axios.put(`citas/${editedItem.value.id}/triaje`, editedItem.value).then(() => {
        dialog.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Aprobación exitosa',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        emit('refresh-data-citas-table')
      }).catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'EditIcon',
            text: `${error.response.data.error}`,
            variant: 'danger',
          },
        })
      })
    }

    const crearFUACitas = item => {
      // console.log(triajeData.value)
      if (triajeData.value.estado === 'pendiente') {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'EditIcon',
            text: 'Primero debes realizar el triaje.',
            variant: 'danger',
          },
        })
        return
      }
      if (item.pivot.estado === 'por aprobar') {
        atencionData.value.servicio_id = item.id
        axios.put(`triajes/${triajeData.value.id}/crear-fua-citas`, atencionData.value)
          .then(response => {
            // console.log(response.data)
            serviciosCitas.value = response.data.data
            emit('refresh-data-citas-table')
            toast({
              component: ToastificationContent,
              props: {
                title: '¡Registro Exitoso!',
                text: 'Ficha Unica de Atención (FUA) creado exitosamente',
                icon: 'CheckSquareIcon',
                variant: 'success',
              },
            })
          })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: '¡Aviso!',
            icon: 'EditIcon',
            text: 'Ya tiene FUA!',
            variant: 'warning',
          },
        })
      }
    }

    const crearFUATriaje = item => {
      // console.log('eeeeeeee', item)
      // console.log(triajeData.value)
      // console.log('Tengo triaje id', triajeData.value.id)
      // Verificamos que el triaje este completado
      if (triajeData.value.estado === 'pendiente') {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'EditIcon',
            text: 'Primero debes realizar el triaje.',
            variant: 'danger',
          },
        })
        return
      }
      if (item.estado === 'por aprobar') {
        atencionData.value.servicio_id = item.id
        axios.put(`triajes/${triajeData.value.id}/crear-fua-triaje/${item.id}`, atencionData.value)
          .then(response => {
            // console.log(response.data)
            serviciosTriaje.value = response.data.data
            emit('refresh-data-citas-table')
            toast({
              component: ToastificationContent,
              props: {
                title: '¡Registro Exitoso!',
                text: 'Ficha Unica de Atención (FUA) creado exitosamente',
                icon: 'CheckSquareIcon',
                variant: 'success',
              },
            })
          })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: '¡Alerta!',
            icon: 'AlertTriangleIcon',
            text: 'Ya tiene Ficha Unica de Atención (FUA)!',
            variant: 'warning',
          },
        })
      }
    }
    const crearFUAInterconsulta = item => {
      if (triajeData.value.estado === 'pendiente') {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'EditIcon',
            text: 'Primero debes realizar el triaje.',
            variant: 'danger',
          },
        })
        return
      }
      if (item.pivot.estado === 'por aprobar') {
        atencionData.value.triaje_id = triajeData.value.id
        atencionData.value.servicio_id = item.id
        axios.put(`atenciones/${item.pivot.model_id}/crear-fua-interconsulta`, atencionData.value)
          .then(response => {
            // Obtenemos todas las atenciones que al menos tengan un servicio asignado que seria una interconsulta
            const atencionesServicios = response.data.atencion.filter(i => i.servicios.length > 0)

            // Debemos almacenar en un Array todos los servicios
            const data = []
            atencionesServicios.forEach(i => {
              data.push(i.servicios)
            })

            serviciosInter.value = data.flat()

            emit('refresh-data-citas-table')
            toast({
              component: ToastificationContent,
              props: {
                title: 'FUA creado exitosamente',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: '¡Aviso!',
            icon: 'EditIcon',
            text: 'Ya tiene FUA!',
            variant: 'warning',
          },
        })
      }
    }
    const agregarServicios = () => {
      // console.log('Estos son los servicios', triajeData.value.servicios)
      // console.log('Estos son los servicios', triajeData.value.servicio)
      triajeData.value.servicio_id = triajeData.value.servicio
      store.dispatch('triajes/addServicioTriaje', triajeData.value)
        .then(response => {
          serviciosTriaje.value = response.data.servicios
          triajeData.value.servicios = response.data.servicios
          // this.toastSuccess('Agregado correctamente')
          toast({
            component: ToastificationContent,
            props: {
              title: '¡Registro exitoso!',
              icon: 'CheckSquareIcon',
              variant: 'success',
            },
          })
          emit('refresh-data-citas-table')
        })
        .catch(error => {
          // console.log(error.response.data)
          toast({
            component: ToastificationContent,
            props: {
              title: '¡Error!',
              text: `${error.response.data.error}`,
              icon: 'CheckSquareIcon',
              variant: 'danger',
            },
          })
        })
    }

    const onSubmit = () => {
      if (editedIndex.value > -1) {
      // vamos a editar
        // console.log('Vamos a editar')
        store.dispatch('triajes/updateCita', triajeData.value)
          .then(() => {
          // Clean form, Close Modal y Refresh Table
            toast({
              component: ToastificationContent,
              props: {
                title: '¡Actualización exitosa',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            emit('refresh-data-citas-table')
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        console.log('Vamos a un nuevo registro')
        store.dispatch('triajes/addCita', triajeData.value)
          .then(() => {
          // Clean form, Close Modal y Refresh Table
            toast({
              component: ToastificationContent,
              props: {
                title: '¡Registro exitoso!',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            dialog.value = false
            emit('refresh-data-citas-table')
          })
      }
    }

    return {
      triajeData,
      resetuserData,
      onSubmit,
      close,
      dialog,
      formTitle,
      aprobarCita,
      atencionData,
      serviciosCitas,
      serviciosInter,
      serviciosTriaje,
      agregarServicios,
      crearFUACitas,
      crearFUATriaje,
      crearFUAInterconsulta,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
