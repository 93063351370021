export default function saludUtilitarios() {
  const resolveColorServicioVariant = val => {
    if (val.codigo === 'SALUD-01-medicina') return 'primary'
    if (val.codigo === 'SALUD-02-odontologia') return 'secondary'
    if (val.codigo === 'SALUD-03-oftamologia') return 'success'
    if (val.codigo === 'SALUD-04-topico') return 'danger'
    if (val.codigo === 'SALUD-05-gineco-obstetrico') return 'obstetricia-color'
    if (val.codigo === 'SALUD-06-psicologia') return 'psicologia-color'
    if (val.codigo === 'SALUD-07-enfermeria') return 'info'
    return 'primary'
  }

  return {
    resolveColorServicioVariant,
  }
}
