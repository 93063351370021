<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Buscar paciente
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          lg="10"
          md="8"
        >
          <b-form-group>
            <vue-autosuggest
              ref="autocomplete"
              v-model="query"
              :suggestions="suggestions"
              :input-props="inputProps"
              :section-configs="sectionConfigs"
              :render-suggestion="renderSuggestion"
              :get-suggestion-value="getSuggestionValue"
              @input="fetchResults"
            />
          </b-form-group>

        </b-col>
        <b-col
          lg="2"
          md="4"
        >
          <b-button
            variant="primary"
            block
            @click="nuevoTriaje"
          >
            Agregar
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import {
  BCard, BAvatar, BButton, BRow, BCol, BCardBody, BCardHeader, BFormGroup,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    VueAutosuggest,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BButton,
    BCardBody,
    BCardHeader,
  },
  data() {
    return {
      // codeAjax,
      query: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 500,
      photosUrl: '/buscar-persona',
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Ingresar número de indentificación, código ó apellidos?',
        class: 'form-control',
        name: 'ajax',
      },
      suggestions: [],
      sectionConfigs: {
        personas: {
          limit: 30,
          label: 'Alumnos',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
        docentes: {
          limit: 30,
          label: 'Docentes',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
      },
    }
  },
  methods: {
    nuevoTriaje() {
      if (this.selected) {
        this.$router.push({ name: 'salud-triajes-create', params: { persona: this.selected.usuario_id } })
        this.selected = null
        document.getElementsByName('ajax')[0].value = null
      } else {
        this.toastAlert('Por favor seleccionar un paciente.', 'top-center')
      }
    },
    fetchResults() {
      const { query } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (query.length < 3) {
          this.toastAlert('Rellenar con más caracteres', 'top-center')
          return
        }
        const personasPromise = this.$http.get(`${this.photosUrl}/${query}`)

        Promise.all([personasPromise]).then(values => {
          this.suggestions = []
          this.selected = null

          const personas = values[0].data.data[0]
          const docentes = values[0].data.data[1]
          // eslint-disable-next-line no-unused-expressions
          personas.length
            && this.suggestions.push({ name: 'personas', data: personas })
          // eslint-disable-next-line no-unused-expressions
          docentes.length
            && this.suggestions.push({ name: 'docentes', data: docentes })
        }).catch(error => {
          // console.log(error.response)
          this.toastError(`${error.response.data.error}`, 'top-center')
        })
      }, this.debounceMilliseconds)
    },
    renderSuggestion(suggestion) {
      return (
        <div>{suggestion.item.apellido_paterno} {suggestion.item.apellido_materno}, {suggestion.item.nombres}</div>
      )
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return `${item.apellido_paterno} ${item.apellido_materno}, ${item.nombres}`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
