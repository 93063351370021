<template>
  <section>

    <b-collapse
      id="collapse-filtros"
    >
      <!-- Filters -->
      <triajes-list-filters
        :estado-filter.sync="estadoFilter"
        :desde-hasta-filter.sync="desdeHastaFilter"
        :estado-options="estadoOptions"
      />
    </b-collapse>

    <b-collapse
      id="collapse-buscar-paciente"
    >
      <buscar-persona @set-add-triaje="(val) => persona = val" />
    </b-collapse>

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                right
              >

                <template #button-content>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="btn-icon"
                  >
                    <feather-icon icon="CommandIcon" />
                  </b-button>
                </template>

                <b-dropdown-item v-b-toggle.collapse-buscar-paciente>
                  <feather-icon
                    icon="ThermometerIcon"
                    size="16"
                  />
                  <span class="align-middle ml-50">Nuevo triaje</span>
                </b-dropdown-item>

                <b-dropdown-item v-b-toggle.collapse-filtros>
                  <feather-icon
                    icon="FilterIcon"
                    size="16"
                  />
                  <span class="align-middle ml-50">Filtros</span>
                </b-dropdown-item>
                <b-dropdown-item v-b-modal.modal_add_infraccion>
                  <feather-icon
                    icon="Share2Icon"
                    size="16"
                  />
                  <span class="align-middle ml-50">Derivar</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item
                  active-class="text-muted"
                  link-class="bg-danger text-white"
                >
                  <feather-icon
                    icon="Share2Icon"
                    size="16"
                  />
                  <span class="align-middle ml-50">Emeregencias</span>
                </b-dropdown-item> -->

              </b-dropdown>
            </div>
          </b-col>
        </b-row>

      </div>

      <!-- Main table element -->
      <b-table
        id="my-table"
        ref="refTablaUsuarios"
        class="position-relative"
        show-empty
        hover
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="fields"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(usuario)="row">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-${resolveUserServicioVariant(row.item.usuario.perfil.persona.nombres)}`"
                :text="avatarText(row.item.usuario.perfil.persona.nombre_completo)"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ row.item.usuario.perfil.persona.nombre_completo }}
            </b-link>
            <small class="text-muted text-monospace py-0">DNI° {{ row.item.usuario.perfil.persona.numero_documento }} - CEL: {{ row.item.usuario.perfil.persona.celular ? row.item.usuario.perfil.persona.celular : '---------' }}</small>
          </b-media>
        </template>

        <!-- Servicios desde Cita -->
        <template #cell(desdeCitas)="data">
          <!-- <pre v-if="data.item.cita">{{ data.item.cita.servicios }}</pre> -->
          <div v-if="data.item.cita">
            <div
              v-for="(servicio, index) in data.item.cita.servicios"
              :key="index"
            >
              <b-badge
                v-if="servicio.pivot.estado === 'aprobado'"
                pill
                variant="light-success"
                class="mr-25"
              >
                <feather-icon
                  icon="CheckIcon"
                />
              </b-badge>

              <b-badge
                v-else
                pill
                variant="light-danger"
                class="mr-25"
              >
                <feather-icon
                  icon="XIcon"
                />
              </b-badge>

              <b-badge
                pill
                :variant="`light-${resolveColorServicioVariant(servicio)}`"
                class="text-capitalize"
              >
                {{ servicio.nombre }}
              </b-badge>
            </div>
          </div>

        </template>

        <!-- Servicios desde Triaje -->
        <template #cell(desdeTriaje)="data">
          <!-- <pre v-if="data.item.cita">{{ data.item.cita.servicios }}</pre> -->
          <div v-if="data.item.servicios">
            <div
              v-for="(servicio, index) in data.item.servicios"
              :key="index"
            >
              <b-badge
                v-if="servicio.pivot.estado === 'aprobado'"
                pill
                variant="light-success"
                class="mr-25"
              >
                <feather-icon
                  icon="CheckIcon"
                />
              </b-badge>

              <b-badge
                v-else
                pill
                variant="light-danger"
                class="mr-25"
              >
                <feather-icon
                  icon="XIcon"
                />
              </b-badge>

              <b-badge
                pill
                :variant="`light-${resolveColorServicioVariant(servicio)}`"
                class="text-capitalize"
              >
                {{ servicio.nombre }}
              </b-badge>
            </div>
          </div>

        </template>

        <!-- Servicios desde Atencione -->
        <template #cell(atencion)="data">
          <!-- <pre>{{ data.value }}</pre> -->

          <div v-if="data.value">
            <div
              v-for="(servicio, index) in data.value"
              :key="index"
            >
              <b-badge
                v-if="servicio.pivot.estado === 'aprobado'"
                pill
                variant="light-success"
                class="mr-25"
              >
                <feather-icon
                  icon="CheckIcon"
                />
              </b-badge>

              <b-badge
                v-else
                pill
                variant="light-danger"
                class="mr-25"
              >
                <feather-icon
                  icon="XIcon"
                />
              </b-badge>

              <b-badge
                pill
                :variant="`light-${resolveColorServicioVariant(servicio)}`"
                class="text-capitalize"
              >
                {{ servicio.nombre }}
              </b-badge>
            </div>
          </div>

        </template>
        <!-- Column: Status -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolveCitaStatusVariant(data.item.estado)}`"
            class="text-capitalize"
          >
            {{ data.item.estado }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="editarItemTriaje(data.item)"
            >
              <feather-icon icon="ThermometerIcon" />
              <span class="align-middle ml-50">Triar</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="asignarServicios(data.item)"
            >
              <feather-icon icon="PlusSquareIcon" />
              <span class="align-middle ml-50">Asignar servicios</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="deleteItem(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>

          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>

    </b-card>

    <triaje-servicio-modal
      :edited-item="editedItem"
      @refresh-data-citas-table="actualizarTabla"
    />
    <derivar-modal />
  </section>
</template>

<script>
import {
  BCard, BTable, BSpinner, VBModal, BRow, BCol, BFormInput, BDropdown, BDropdownItem, BPagination, BBadge, BButton, BMedia,
  BAvatar,
  BLink,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import BuscarPersona from '@/views/salud/triaje/BuscarPersona.vue'
import triajeStoreModule from '../triajeStoreModule'
import TriajeServicioModal from './TriajeServicioModal.vue'
import useSaludUtilitarios from '../../useSaludUtilitarios'
import TriajesListFilters from './TriajesListFilters.vue'
import DerivarModal from './DerivarModal.vue'

export default {
  components: {
    // BCardBody,
    DerivarModal,
    BCollapse,
    TriajesListFilters,
    BTable,
    BBadge,
    vSelect,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BAvatar,
    BLink,
    // componentes local
    BuscarPersona,
    TriajeServicioModal,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      estadoFilter: null,
      desdeHastaFilter: '',
      persona: {},
      editedItem: {},
      isBusy: false,
      mostrar: false,
      items: [],
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 100],
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      selectedPersona: {},
    }
  },
  watch: {
    estadoFilter() {
      this.actualizarTabla()
    },
    desdeHastaFilter() {
      this.actualizarTabla()
    },
  },
  methods: {
    serviciosInter(value) {
      // Obtenemos todas las atenciones que al menos tengan un servicio asignado que seria una interconsulta
      const atencionesServicios = value.filter(item => item.servicios.length > 0)

      // Debemos almacenar en un Array todos los servicios
      const data = []
      atencionesServicios.forEach(item => {
        data.push(item.servicios)
      })
      return data.flat()
    },
    editarItemTriaje(item) {
      // console.log('estamos editando triaje', item)
      // Si atenciones > 0 Ya no puede actualizar el triaje.
      const aprobados = item.servicios.filter(i => i.pivot.estado === 'aprobado')
      // if (item.atencion.length <= 0) {
      if (aprobados.length <= 0) {
        this.$router.push({ name: 'salud-triajes-edit', params: { id: item.id } })
      } else {
        this.toastAlert('Ya no puedes actualizar el triaje')
      }
    },
    asignarServicios(item) {
      const { usuario, ...rest } = item
      const { perfil } = usuario
      const { persona } = perfil
      this.editedItem = { ...rest, persona }

      this.$root.$emit('bv::show::modal', 'modal-aprobar-servicios', '#refModalAprobarServicios')
    },
    deleteItem(item) {
      this.$swal({
        title: '¿Estas seguro?',
        text: '¡No podrás revertir esto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, bórralo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('triajes/deleteTriaje', item)
            .then(response => {
              const id = String(response.data.id).padStart(6, '0')
              this.toastSuccess(`Se eliminó el triaje N° ${id}`, 'top-right', '¡Eliminado correctamente!')
              this.actualizarTabla()
            }).catch(error => {
              this.toastError(`${error.response.data.error}`)
              this.$swal({
                title: 'Error',
                text: `${error.response.data.error}`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Tu triaje está seguro :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    actualizarTabla() {
      this.$refs.refTablaUsuarios.refresh()
    },
    async myProvider(ctx) {
      // eslint-disable-next-line no-param-reassign
      ctx.estado = this.estadoFilter
      // eslint-disable-next-line no-param-reassign
      ctx.desde = this.desdeHastaFilter.substring(0, 10)
      // eslint-disable-next-line no-param-reassign
      ctx.hasta = this.desdeHastaFilter.substring(14, 24)

      const promise = store.dispatch('triajes/fetchTriajes', { queryParams: ctx })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data

        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0

        this.to = to || 0
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
  },
  setup() {
    const TRIAJES_APP_STORE_MODULE_NAME = 'triajes'

    // Register module
    if (!store.hasModule(TRIAJES_APP_STORE_MODULE_NAME)) store.registerModule(TRIAJES_APP_STORE_MODULE_NAME, triajeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRIAJES_APP_STORE_MODULE_NAME)) store.unregisterModule(TRIAJES_APP_STORE_MODULE_NAME)
    })

    const resolveCitaStatusVariant = val => {
      if (val === 'completado') return 'success'
      if (val === 'pendiente') return 'danger'
      return 'primary'
    }

    const { resolveColorServicioVariant } = useSaludUtilitarios()

    const fields = [
      // { key: 'id', label: 'ID' },
      { key: 'usuario', label: 'paciente', sorTable: false },
      // { key: 'desdeCitas', label: 'cita' },
      { key: 'desdeTriaje', label: 'triaje', sorTable: false },
      {
        key: 'atencion', label: 'interconsulta', formatter: 'serviciosInter', sorTable: false,
      },
      { key: 'created_at', label: 'f. reg', sorTable: false },
      { key: 'estado', sorTable: false },
      { key: 'acciones', sorTable: false },
    ]

    const resolveUserServicioVariant = val => {
      if (val === 'FARMACIA') return 'primary'
      if (val === 'ODONTOLOGÍA') return 'warning'
      if (val === 'OFTAMOLOGÍA') return 'success'
      if (val === 'TÓPICO') return 'info'
      if (val === 'GINECO-OBSTÉTRICO') return 'danger'
      return 'primary'
    }

    const estadoOptions = [
      { label: 'Todos', value: '' },
      { label: 'Pendiente', value: 'pendiente' },
      { label: 'Completado', value: 'completado' },
    ]

    return {
      // Filter
      estadoOptions,
      avatarText,
      fields,
      resolveCitaStatusVariant,
      resolveUserServicioVariant,
      resolveColorServicioVariant,
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
