<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filtros
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row align-h="between">
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Desde - Hasta</label>
          <flat-pickr
            :value="desdeHastaFilter"
            class="form-control"
            :config="{ mode: 'range', altInput: true, altFormat: 'd/m/Y', dateFormat: 'Y-m-d', defaultDate: new Date()}"
            @input="(val) => $emit('update:desdeHastaFilter', val)"
          />
        </b-col>
        <!-- <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Plan</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="planFilter"
            :options="planOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:planFilter', val)"
          />
        </b-col> -->
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Estado</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="estadoFilter"
            :options="estadoOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:estadoFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    flatPickr,
  },
  props: {
    desdeHastaFilter: {
      type: [String, null],
      default: null,
    },
    // planFilter: {
    //   type: [String, null],
    //   default: null,
    // },
    estadoFilter: {
      type: [String, null],
      default: null,
    },
    // roleOptions: {
    //   type: Array,
    //   required: true,
    // },
    // planOptions: {
    //   type: Array,
    //   required: true,
    // },
    estadoOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rangeDate: '',
      // rangeDate: `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`,
    }
  },
  watch: {
    rangeDate() {
      // console.log(this.rangeDate)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
